export interface INavigationRoute {
  name: string;
  displayName: string;
  meta: { icon: string };
  children?: INavigationRoute[];
}

export default {
  root: {
    name: "/",
    displayName: "navigationRoutes.home",
  },
  routes: [
    {
      name: "dashboard",
      displayName: "menu.dashboard",
      meta: {
        icon: "vuestic-iconset-dashboard",
      },
    },
    {
      name: "offers",
      displayName: "menu.offers",
      meta: {
        icon: "attach_money",
      },
    },
    {
      name: "analytics",
      displayName: "menu.analytics",
      meta: {
        icon: "analytics",
      },
    },
    {
      name: "settings",
      displayName: "menu.settings",
      meta: {
        icon: "settings",
      },
    },
  ] as INavigationRoute[],
};

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useUserStore } from "../stores/user-store";

import AppLayout from "../layouts/AppLayout.vue";
import AuthLayout from "../layouts/AuthLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "dashboard" },
  },
  {
    name: "admin",
    path: "/",
    component: AppLayout,
    redirect: { name: "dashboard" },
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        component: () => import("../pages/dashboard/Dashboard.vue"),
      },
      {
        name: "offers",
        path: "offers",
        component: () => import("../pages/offers/Offers.vue"),
      },
      {
        name: "offer",
        path: "/offers/:offer_id",
        component: () => import("../pages/offers/Offer.vue"),
      },
      {
        name: "analytics",
        path: "analytics",
        component: () => import("../pages/analytics/Analytics.vue"),
      },
      {
        name: "settings",
        path: "settings",
        component: () => import("../pages/settings/Settings.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("../pages/Login.vue"),
      },
      {
        path: "",
        redirect: { name: "login" },
      },
    ],
  },
  {
    name: "404",
    path: "/404",
    component: () => import("../pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
  routes,
});

router.beforeEach((to, _, next) => {
  const userStore = useUserStore();

  if (to.name !== "login" && !userStore.isAuthorized) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;

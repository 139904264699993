<template>
  <VaLayout class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <main
        class="h-full flex items-center justify-center mx-auto max-w-[420px]"
      >
        <RouterView />
      </main>
    </template>
  </VaLayout>
</template>

<template>
  <div class="profile-dropdown-wrapper">
    <VaDropdown
      v-model="isShown"
      :offset="[9, 0]"
      class="profile-dropdown"
      stick-to-edges
    >
      <template #anchor>
        <VaButton preset="secondary" color="textPrimary">
          <span class="profile-dropdown__anchor min-w-max">
            <slot />
            Tele Club (123456)<br />
            info@telecom-club.com
          </span>
        </VaButton>
      </template>
      <VaDropdownContent
        class="profile-dropdown__content md:w-60 px-0 py-4 w-full"
        :style="{ '--hover-color': hoverColor }"
      >
        <VaList>
          <VaListItem
            class="menu-item px-4 text-base cursor-pointer h-8"
            @click="logout"
          >
            <VaIcon name="logout" class="pr-1" color="secondary" />
            Log out
          </VaListItem>
        </VaList>
      </VaDropdownContent>
    </VaDropdown>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { useColors } from "vuestic-ui";
import { useUserStore } from "../../../../stores/user-store";
import { useRouter } from "vue-router";

const { colors, setHSLAColor } = useColors();
const hoverColor = computed(() => setHSLAColor(colors.focus, { a: 0.1 }));

type ProfileListItem = {
  name: string;
  to?: string;
  href?: string;
  icon: string;
};

type ProfileOptions = {
  name: string;
  separator: boolean;
  list: ProfileListItem[];
};

withDefaults(
  defineProps<{
    options?: ProfileOptions[];
  }>(),
  {
    options: () => [
      {
        name: "account",
        separator: true,
        list: [
          {
            name: "profile",
            to: "preferences",
            icon: "mso-account_circle",
          },
          {
            name: "settings",
            to: "settings",
            icon: "mso-settings",
          },
          {
            name: "billing",
            to: "billing",
            icon: "mso-receipt_long",
          },
          {
            name: "projects",
            to: "projects",
            icon: "mso-favorite",
          },
        ],
      },
      {
        name: "explore",
        separator: true,
        list: [
          {
            name: "faq",
            to: "faq",
            icon: "mso-quiz",
          },
          {
            name: "helpAndSupport",
            href: "https://discord.gg/u7fQdqQt8c",
            icon: "mso-error",
          },
        ],
      },
      {
        name: "",
        separator: false,
        list: [
          {
            name: "logout",
            to: "login",
            icon: "mso-logout",
          },
        ],
      },
    ],
  },
);

const isShown = ref(false);

const { updateUser } = useUserStore();
const { push } = useRouter();

const logout = () => {
  updateUser(null);
  push({ name: "login" });
};
</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__content {
    .menu-item:hover {
      background: var(--hover-color);
    }
  }

  &__anchor {
    display: inline-block;
  }
}
</style>

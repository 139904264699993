import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { User } from "../services/entity";
import Storage from "../services/storage";

export const userStorage = new Storage<User>("user");

export const isAuthorized = (): boolean => !!userStorage.get();

export const useUserStore = defineStore("user", () => {
  const user = ref(userStorage.get());
  const isAuthorized = computed(() => !!user.value);

  const updateUser = (userData?: User | null) => {
    if (userData) {
      userStorage.set(userData);
      user.value = userData;
    } else {
      userStorage.remove();
      user.value = null;
    }
  };

  return {
    user,
    isAuthorized,
    updateUser,
  };
});
